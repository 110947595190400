import React, { Component } from "react";
import OrderService from "../services/OrderService";
import SideBarComponent from "./SideBarComponent";
import SideBarStoreComponent from "./SideBarStoreComponent";

import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";

class ViewOrderComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: JSON.parse(localStorage.getItem("user")),
      id: this.props.match.params.id,
      order: {},
      rider: {},
    };

    this.viewOrders = this.viewOrders.bind(this);
  }

  componentDidMount() {
    OrderService.getOrderById(this.state.id).then((res) => {
      console.log("order data", res.data);
      this.setState({ order: res.data });
      this.setState({ rider: res.data.rider });
    });
  }

  viewOrders() {
    this.props.history.push("/orders");
  }

  render() {
    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        {this.state.currentUser?.roles?.includes("ROLE_ADMIN") && (
          <SideBarComponent />
        )}
        {this.state.currentUser?.roles?.includes("ROLE_STORE") && (
          <SideBarStoreComponent />
        )}
        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Order</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">
                        View Order
                        <button
                          className="btn btn-primary"
                          onClick={this.viewOrders}
                        >
                          List Order
                        </button>
                      </h6>
                    </div>
                    <div className="card-body">
                      {/* <h2 className="text-center">Orders List</h2> */}
                      <div className="row">
                        <div className="card col-md-6">
                          <h3 className="text-center"> Order Details</h3>
                          <div className="card-body">
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Order ID:{" "}
                              </label>
                              <div> {this.state.order.orderId}</div>
                            </div>
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Order Ref Number:{" "}
                              </label>
                              <div> {this.state.order.orderNumber}</div>
                            </div>

                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Order Date:{" "}
                              </label>
                              <div> {this.state.order.orderDate}</div>
                            </div>
                            
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Order Value:{" "}
                              </label>
                              <div>
                              &#x20B9; {this.state.order.orderValue?.toFixed(2)}/- 
                              </div>
                            </div>
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Order Distance:{" "}
                              </label>
                              <div>
                                {this.state.order.orderDistance?.toFixed(2)} KMs
                              </div>
                            </div>
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Order Status:{" "}
                              </label>
                              <div> {this.state.order.orderStatus}</div>
                            </div>
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Order Update Date:{" "}
                              </label>
                              <div> {this.state.order.orderUpdateDate}</div>
                            </div>
                          </div>
                        </div>
                        <div className="card col-md-6">
                          <h3 className="text-center"> Customer Details</h3>
                          <div className="card-body">
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Customer Name:{" "}
                              </label>
                              <div> {this.state.order.customerName}</div>
                            </div>
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Customer Address:{" "}
                              </label>
                              <div> {this.state.order.customerAddress}</div>
                            </div>

                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Customer Contact:{" "}
                              </label>
                              <div> {this.state.order.mobileNumber}</div>
                            </div>
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Payment Type:{" "}
                              </label>
                              <div> {this.state.order.paymentType}</div>
                            </div>
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Payment Status:{" "}
                              </label>
                              <div> {this.state.order.paymentStatus}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="card col-md-6">
                          <h3 className="text-center"> Rider Details</h3>
                          <div className="card-body">
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Rider Name:{" "}
                              </label>
                              <div> {this.state.rider?.fullname}</div>
                            </div>
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Rider Contact:{" "}
                              </label>
                              <div> {this.state.rider?.mobile} </div>
                            </div>

                            {this.state.currentUser?.roles?.includes(
                              "ROLE_ADMIN"
                            ) && (
                              <div className="row">
                                <label style={{ fontWeight: "bold" }}>
                                  {" "}
                                  Rider Cost:{" "}
                                </label>
                                <div>
                                &#x20B9; {this.state.order?.riderCost?.toFixed(2)}/-
                                </div>
                              </div>
                            )}
                            {this.state.currentUser?.roles?.includes(
                              "ROLE_ADMIN"
                            ) && (
                              <div className="row">
                                <label style={{ fontWeight: "bold" }}>
                                  {" "}
                                  Rider Payment Status:{" "}
                                </label>
                                <div>
                                  {this.state.order?.riderPaymentStatus}
                                </div>
                              </div>
                            )}
                            {this.state.currentUser?.roles?.includes(
                              "ROLE_ADMIN"
                            ) && (
                              <div className="row">
                                <label style={{ fontWeight: "bold" }}>
                                  Rider Payment Date:
                                </label>
                                <div>
                                  {this.state.order?.riderPaymentDate}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="card col-md-6">
                          {this.state.currentUser?.roles?.includes(
                            "ROLE_ADMIN"
                          ) && <h3 className="text-center"> Store / Vendor Details</h3>}

                          <div className="card-body">
                          <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Store Name:{" "}
                              </label>
                              <div> {this.state.order?.store?.vendor?.vendorName} - {this.state.order?.store?.storeName}</div>
                            </div>
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Store Address:{" "}
                              </label>
                              <div> {this.state.order?.store?.storeAddress}</div>
                            </div>
                            
                            {this.state.currentUser?.roles?.includes(
                              "ROLE_ADMIN"
                            ) && (
                              <div className="row">
                                <label style={{ fontWeight: "bold" }}>
                                  Vendor Cost:{" "}
                                </label>
                                <div>
                                 &#x20B9; {this.state.order?.vendorCost?.toFixed(2)}/- 
                                </div>
                              </div>
                            )}
                            {this.state.currentUser?.roles?.includes(
                              "ROLE_ADMIN"
                            ) && (
                              <div className="row">
                                <label style={{ fontWeight: "bold" }}>
                                  Vendor Payment Status:{" "}
                                </label>
                                <div>
                                  {this.state.order?.vendorPaymentStatus}
                                </div>
                              </div>
                            )}
                            {this.state.currentUser?.roles?.includes(
                              "ROLE_ADMIN"
                            ) && (
                              <div className="row">
                                <label style={{ fontWeight: "bold" }}>
                                  Vendor Payment Date:{" "}
                                </label>
                                <div>{this.state.order?.vendorPaymentDate}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default ViewOrderComponent;

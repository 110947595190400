import React, { Component } from "react";
import OrderService from "../services/OrderService";

import SideBarComponent from "./SideBarComponent";
import { confirm } from "react-confirm-box";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";
import AttendanceService from "../services/AttendanceService";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";

import { faEye, faIndianRupee } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import addDays from "date-fns/addDays";
import DatePicker from "react-datepicker";
import { faImage } from "@fortawesome/free-regular-svg-icons";

class ListRiderAttendanceComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      orderStatus: "ALL",
      tableData: {},
      orders: [],
      selctedOrderRows: [],
      currentUser: null,
      orderStatus: "",
      startDate: addDays(new Date(), -2),
      endDate: new Date(),
      orderStatuses: [
        { label: "OPEN" },
        { label: "ACCEPTED" },
        { label: "DELIVERED" },
        { label: "CANCELLED" },
      ],

      columns: [
        {
          name: "Attendance Id",
          selector: (row) => row.attendanceId,
          cellExport: (row) => row.attendanceId,
          sortable: true,
          width: 6,
        },
        {
          name: "Rider Name",
          selector: (row) => row.rider.fullname,
          cellExport: (row) => row.rider.fullname,
          sortable: true,
          width: 6,
        },

        {
          name: "Mobile Number",
          selector: (row) => row.rider.mobile,
          cellExport: (row) => row.rider.mobile,
          sortable: true,
          width: 6,
        },
        {
          name: "Login Time",
          selector: (row) => row.attendanceDateTime,
          cellExport: (row) => row.attendanceDateTime,
          sortable: true,
        },
        {
          name: "Logout Time",
          selector: (row) => row.attendanceOutDateTime,
          cellExport: (row) => row.attendanceOutDateTime,
          sortable: true,
        },
       

        {
          cell: (row) => (
            <a href={`/view-rider-attendance/${row["attendanceId"]}`}>
              <FontAwesomeIcon icon={faImage} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
    };

    this.viewRiders = this.viewRiders.bind(this);
  }

  componentDidMount() {
    if (this.state.id === "0") {
   
      return;
    } else {
      const currentUser = AuthService.getCurrentUser();
      if (!currentUser) {
        this.setState({ redirect: "/login" });
      }
      this.setState({ currentUser: currentUser, userReady: true });
      console.log("currentUser" + JSON.stringify(currentUser?.roles));
  
      if (!currentUser?.roles.includes("ROLE_ADMIN")) {
        console.log("currentUser" + JSON.stringify(currentUser.roles));
        this.setState({ redirect: "/noaccess" });
      }
      
      AttendanceService.riderAttendance(this.state.id).then((res) => {

        // console.log(res.data);

        this.setState({ attendance: res.data });

        this.setState({
          tableData: { columns: this.state.columns, data: res.data },
        });

        
      });
    }
  }
  viewRiders() {
    this.props.history.push("/riders");
  }

 

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />
        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Order</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div className="card shadow mb-4">
                  <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">
                        Rider Attendance
                        <button
                          className="btn btn-primary"
                          onClick={this.viewRiders}
                        >
                          {" "}
                          List Rider
                        </button>
                      </h6>
                    </div>
                    <div className="card-body">
                      {/* <form onSubmit={this.onFormSubmit}>
                        <div className="row">
                          <div className="col-md-3">
                            Start Date:
                            <DatePicker
                              selected={this.state.startDate}
                              onChange={this.handleStartDateChange}
                              name="startDate"
                              dateFormat="dd-MM-yyyy"
                            />
                          </div>

                          <div className="col-md-3">
                            End Date:{" "}
                            <DatePicker
                              selected={this.state.endDate}
                              onChange={this.handleEndDateChange}
                              name="endDate"
                              dateFormat="dd-MM-yyyy"
                            />
                          </div>

                          <div className="col-md-3">
                            Order Status:
                            <select
                              name="orderStatus"
                              value={this.state.orderStatus}
                              onChange={this.orderStatusHandler}
                            >
                              <option value="">Select Order Status</option>
                              {this.state.orderStatuses.map((orderStatus) => (
                                <option value={orderStatus.label}>
                                  {orderStatus.label}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-md-3">
                            <button className="btn btn-primary">Search</button>
                          </div>
                        </div>
                      </form> */}

                      <div className="row">
                        <DataTableExtensions {...this.state.tableData}>
                          <DataTable
                            columns={this.state.columns}
                            data={this.state.orders}
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover
                            selectableRows
                            print={false}
                            exportHeaders={true}
                            onSelectedRowsChange={this.handleChange}
                            selectedRows={this.handleChange}
                          />
                        </DataTableExtensions>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default ListRiderAttendanceComponent;

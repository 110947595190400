import axios from "axios";

const DASHBOARD_API_BASE_URL =
  "https://api.richgoldshine.com/quick/api/v1/adminSettings";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && user.accessToken ? user.accessToken : "";
class AdminSettingsService {
  getAdminSettingsById(adminSettingsId) {
    return axios.get(DASHBOARD_API_BASE_URL + "/" + adminSettingsId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  updateAdminSettings(adminSettings, adminSettingsId) {
    return axios.put(
      DASHBOARD_API_BASE_URL + "/" + adminSettingsId,
      adminSettings,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
}

export default new AdminSettingsService();

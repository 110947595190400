import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import OrderService from "../services/OrderService";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import SideBarComponent from "./SideBarComponent";
import SideBarStoreComponent from "./SideBarStoreComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";
import AuthService from "../services/auth.service";

class CreateOrderComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // step 2
      currentUser: null,
      id: this.props.match.params.id,
      orderNumber: "",
      orderDistance: "0",

      orderNumberVal: "",
      orderValue: "",
      customerName: "",
      customerAddress: "",
      mobileNumber: "",
      orderDate: "",
      orderUpdateDate: "",
      paymentType: "",
      paymentStatus: "",
      orderStatus: "OPEN",
      paymentTypes: [{ label: "CASH" }, { label: "ONLINE" }, { label: "CARD" }],
      paymentStatuses: [{ label: "PAID" }, { label: "UNPAID" }],
      address: "",
      customerLatitude: 0.0,
      customerLongitude: 0.0,
      searchOptions: {
        componentRestrictions: { country: ["in"] },
      },
    };
    // this.changeOrderNameHandler = this.changeOrderNameHandler.bind(this);
    this.saveOrUpdateOrder = this.saveOrUpdateOrder.bind(this);
  }

  // step 3
  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      this.setState({ redirect: "/login" });
    }
    this.setState({ currentUser: currentUser, userReady: true });
    console.log("currentUser" + JSON.stringify(currentUser?.roles));

    if (!currentUser?.roles.includes("ROLE_STORE")) {
      console.log("currentUser" + JSON.stringify(currentUser.roles));
      this.setState({ redirect: "/noaccess" });
    }

    if (this.state.id === "0") {
      return;
    } else {
      OrderService.getOrderById(this.state.id).then((res) => {
        let order = res.data;
        console.log("Order ", res.data);
        this.setState({
          orderNumber: order.orderNumber,
          // orderDistance: order.orderDistance,
          orderValue: order.orderValue,
          customerName: order.customerName,
          customerAddress: order.customerAddress,
          mobileNumber: order.mobileNumber,
          orderDate: order.orderDate,
          orderUpdateDate: order.orderUpdateDate,
          paymentType: order.paymentType,
          paymentStatus: order.paymentStatus,
          orderStatus: order.orderStatus,
        });
      });
    }
  }
  saveOrUpdateOrder = (e) => {
    e.preventDefault();
    if (this.state.orderNumber === "") {
      this.setState({ orderNumberVal: "Invalid!, Please enter  order number" });
      return false;
    } else {
      this.setState({ orderNumberVal: "" });
    }

    if (this.state.orderValue === "") {
      this.setState({ orderValueVal: "Invalid!, Please enter  order value" });
      return false;
    } else {
      this.setState({ orderValueVal: "" });
    }

    if (this.state.customerName === "") {
      this.setState({
        customerNameVal: "Invalid!, Please enter  customer name",
      });
      return false;
    } else {
      this.setState({ customerNameVal: "" });
    }

    if (this.state.customerAddress === "") {
      this.setState({
        customerAddressVal: "Invalid!, Please enter  customer address",
      });
      return false;
    } else {
      this.setState({ customerAddressVal: "" });
    }

    if (this.state.mobileNumber === "") {
      this.setState({
        mobileNumberVal: "Invalid!, Please enter  mobile number",
      });
      return false;
    } else {
      this.setState({ mobileNumberVal: "" });
    }

    if (this.state.orderDate === " ") {
      this.setState({ orderDateVal: "Invalid!, Please enter  order date" });
      return false;
    } else {
      this.setState({ orderDateVal: "" });
    }

    if (this.state.orderCompleteDate === "") {
      this.setState({
        orderCompleteDateVal: "Invalid!, Please enter  order complete date",
      });
      return false;
    } else {
      this.setState({ orderCompleteDateVal: "" });
    }

    if (this.state.paymentType === "") {
      this.setState({ paymentTypeVal: "Invalid!, Please enter  payment type" });
      return false;
    } else {
      this.setState({ paymentTypeVal: "" });
    }

    if (this.state.paymentStatus === "") {
      this.setState({
        paymentStatusVal: "Invalid!, Please enter  payment status",
      });
      return false;
    } else {
      this.setState({ paymentStatusVal: "" });
    }

    if (this.state.orderDistance > 200) {
      this.setState({
        customerAddressVal:
          "Pleae check order address, the delivery distance is: " +
          this.state.orderDistance +
          " kms",
      });

      // alert(
      //   "Pleae check order address, the delivery distance is: " +
      //   this.state.orderDistance +
      //     " kms"
      // );

      return false;
    }

    let order = {
      orderNumber: this.state.orderNumber,
      orderValue: this.state.orderValue,
      customerName: this.state.customerName,
      customerAddress: this.state.customerAddress,
      customerLatitude: this.state.customerLatitude,
      customerLongitude: this.state.customerLongitude,
      mobileNumber: this.state.mobileNumber,
      orderDate: this.state.orderDate,
      orderCompleteDate: this.state.orderCompleteDate,
      paymentType: this.state.paymentType,
      paymentStatus: this.state.paymentStatus,
      orderStatus: this.state.orderStatus,
      orderDistance: this.state.orderDistance,
      store: { storeId: this.state.currentUser.storeId },
    };
    // alert("Edit: "+JSON.stringify(order));

    // step 5
    if (this.state.id === "0") {
      OrderService.createOrder(order).then((res) => {
        this.props.history.push("/orders");
      });
    } else {
      OrderService.updateOrder(order, this.state.id).then((res) => {
        this.props.history.push("/orders");
      });
    }
  };

  changeOrderNumberHandler = (event) => {
    this.setState({ orderNumber: event.target.value });
  };

  changeOrderValueHandler = (event) => {
    this.setState({ orderValue: event.target.value });
  };

  changecustomerNameHandler = (event) => {
    this.setState({ customerName: event.target.value });
  };
  changemobileNumberHandler = (event) => {
    this.setState({ mobileNumber: event.target.value });
  };

  changecustomerAddressHandler = (event) => {
    this.setState({ customerAddress: event.target.value });
  };
  fetchSuggestions = async (address) => {
    if (address.length < 3) return; // Don't call the API if less than 3 characters

    this.setState({ loading: true });

    const googleMapsAPIKey = "YOUR_GOOGLE_API_KEY"; // Replace with your actual API key
    const apiUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA6Vj3VKcguJGlp1XArf_84dkQyPTvNWH8&libraries=places`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      if (data.status === "OK") {
        this.setState({
          suggestions: data.predictions, // Set the fetched suggestions
          loading: false,
        });
      } else {
        this.setState({ loading: false, suggestions: [] });
      }
    } catch (error) {
      console.error("Error fetching Google Places API:", error);
      this.setState({ loading: false });
    }
  };

  handleChange = (address) => {
    this.setState({ address });
    this.fetchSuggestions(address);
    // alert("hiii");
  };

  handleSelect = (address) => {
    this.setState({ customerAddress: address });

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({ customerLatitude: latLng.lat });
        this.setState({ customerLongitude: latLng.lng });
        let order = {
          orderNumber: this.state.orderNumber,
          orderValue: this.state.orderValue,
          customerName: this.state.customerName,
          customerAddress: this.state.customerAddress,
          customerLatitude: this.state.customerLatitude,
          customerLongitude: this.state.customerLongitude,
          mobileNumber: this.state.mobileNumber,
          orderDate: this.state.orderDate,
          orderCompleteDate: this.state.orderCompleteDate,
          paymentType: this.state.paymentType,
          paymentStatus: this.state.paymentStatus,
          orderStatus: this.state.orderStatus,
          orderDistance: this.state.orderDistance,
          store: { storeId: this.state.currentUser.storeId },
        };
        // alert(JSON.stringify(order));
        OrderService.getOrderDistance(order).then((res) => {
          this.setState({
            orderDistance: res.data.orderDistance,
          });
          // alert(res.data.orderDistance);
          if (res.data.orderDistance > 200) {
            this.setState({
              customerAddressVal:
                "Pleae check order address, the delivery distance is: " +
                res.data.orderDistance +
                " kms",
            });

            return false;
          }
        });
      })
      .catch((error) => console.error("Error", error));
  };
  changeorderDateHandler = (event) => {
    this.setState({ orderDate: event.target.value });
  };
  changeorderCompleteDateHandler = (event) => {
    this.setState({ orderCompleteDate: event.target.value });
  };
  paymentTypeHandler = (event) => {
    this.setState({ paymentType: event.target.value });
  };
  paymentStatusHandler = (event) => {
    this.setState({ paymentStatus: event.target.value });
  };

  cancel() {
    this.props.history.push("/orders");
  }

  getTitle() {
    if (this.state.id === "0") {
      return <h6 className="m-0 font-weight-bold text-primary">Add Order</h6>;
    } else {
      return (
        <h6 className="m-0 font-weight-bold text-primary">Update Order</h6>
      );
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    //     const { currentUser } = this.state;

    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        {this.state.currentUser?.roles?.includes("ROLE_ADMIN") && (
          <SideBarComponent />
        )}
        {this.state.currentUser?.roles?.includes("ROLE_STORE") && (
          <SideBarStoreComponent />
        )}
        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Order</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <a
                        className="btn btn-sm btn-primary float-right   mr-3"
                        href="/orders"
                        role="button"
                      >
                        List Order
                      </a>

                      {this.getTitle()}
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="card col-md-6 offset-md-3 offset-md-3">
                          <div className="card-body">
                            <form>
                              <div className="form-group">
                                <label> Order Number: </label>
                                <input
                                  placeholder="Order Number"
                                  name="orderNumber"
                                  className="form-control"
                                  value={this.state.orderNumber}
                                  onChange={this.changeOrderNumberHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.orderNumberVal}
                                </p>
                              </div>

                              <div className="form-group">
                                <label> Order Value: </label>
                                <input
                                  placeholder="Order Value"
                                  name="orderValue"
                                  className="form-control"
                                  value={this.state.orderValue}
                                  onChange={this.changeOrderValueHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.orderValueVal}
                                </p>
                              </div>

                              <div className="form-group">
                                <label> Customer Name: </label>
                                <input
                                  placeholder="Customer Name"
                                  name="customerName"
                                  className="form-control"
                                  value={this.state.customerName}
                                  onChange={this.changecustomerNameHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.customerNameVal}
                                </p>
                              </div>

                              <PlacesAutocomplete
                                value={this.state.address}
                                onChange={this.handleChange}
                                onSelect={this.handleSelect}
                                searchOptions={this.state.searchOptions}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div>
                                    <input
                                      {...getInputProps({
                                        placeholder: "Search Places ...",
                                        className: "location-search-input",
                                      })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {this.state.address.length >= 3 &&
                                        suggestions.map((suggestion) => {
                                          const className = suggestion.active
                                            ? "suggestion-item--active"
                                            : "suggestion-item";
                                          // inline style for demonstration purpose
                                          const style = suggestion.active
                                            ? {
                                                backgroundColor: "#fafafa",
                                                cursor: "pointer",
                                              }
                                            : {
                                                backgroundColor: "#ffffff",
                                                cursor: "pointer",
                                              };
                                          return (
                                            <div
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className,
                                                  style,
                                                }
                                              )}
                                            >
                                              <span>
                                                {suggestion.description}
                                              </span>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>

                              <div className="form-group">
                                <label> Customer Address: </label>
                                <input
                                  placeholder="Customer Address"
                                  name="customerAddress"
                                  className="form-control"
                                  value={this.state.customerAddress}
                                  onChange={this.changecustomerAddressHandler}
                                  readOnly
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.customerAddressVal}
                                </p>
                              </div>
                              <div className="form-group">
                                <label> Mobile Number: </label>
                                <input
                                  placeholder="Mobile Number"
                                  maxLength="11"
                                  name="mobileNumber"
                                  className="form-control"
                                  value={this.state.mobileNumber}
                                  onChange={this.changemobileNumberHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.mobileNumberVal}
                                </p>
                              </div>
                              <div className="form-group">
                                <label> Payment Type: </label>

                                <select
                                  name="paymentType"
                                  value={this.state.paymentType}
                                  onChange={this.paymentTypeHandler}
                                >
                                  <option value="">
                                    Select Payment Status
                                  </option>
                                  {this.state.paymentTypes.map(
                                    (paymentType1) => (
                                      <option value={paymentType1.label}>
                                        {paymentType1.label}{" "}
                                      </option>
                                    )
                                  )}
                                </select>
                                <p className="text-danger">
                                  {" "}
                                  {this.state.paymentTypeVal}
                                </p>
                              </div>
                              <div className="form-group">
                                <label> Payment Status: </label>
                                <select
                                  name="paymentStatus"
                                  value={this.state.paymentStatus}
                                  onChange={this.paymentStatusHandler}
                                >
                                  <option value="">
                                    Select Payment Status
                                  </option>
                                  {this.state.paymentStatuses.map(
                                    (paymentStatus1) => (
                                      <option value={paymentStatus1.label}>
                                        {paymentStatus1.label}{" "}
                                      </option>
                                    )
                                  )}
                                </select>
                                <p className="text-danger">
                                  {" "}
                                  {this.state.paymentStatusVal}
                                </p>
                              </div>

                              <button
                                className="btn btn-success"
                                onClick={this.saveOrUpdateOrder}
                              >
                                Save
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={this.cancel.bind(this)}
                                style={{ marginLeft: "10px" }}
                              >
                                Cancel
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default CreateOrderComponent;

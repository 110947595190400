import React, { Component } from 'react'

class BackEndFooterComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
                 
        }
    }

    render() {
        return (
            <div>
                  {/* <!-- Footer --> */}
        <footer className="sticky-footer bg-white">
            <div className="container my-auto">
                <div className="copyright text-center my-auto">
                    <span>Copyright &copy; <a href="https://www.quickdelivary.com">Quick Delivary</a> 2022</span>
                    
                    <span>  Powered By <a href="http://www.xform.in">Xform Technologies, Pune</a></span>
                </div>
            </div>
        </footer>
        {/* <!-- End of Footer --> */}
            </div>
        ) 
    }
}

export default BackEndFooterComponent

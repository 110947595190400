import React, { Component } from "react";
import SideBarComponent from "./SideBarComponent";
import SideBarStoreComponent from "./SideBarStoreComponent";

import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";
import AuthService from "../services/auth.service";

class ChangePasswordComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // step 2
      id: this.props.match.params.id,
      password: "",
      passwordVal: "",
      confirmPassword: "",
      confirmPasswordVal: "",
      currentUser: null,

    };
    this.changePasswordHandler = this.changePasswordHandler.bind(this);
    this.saveOrUpdatePassword = this.saveOrUpdatePassword.bind(this);
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      this.setState({ redirect: "/login" });
    }
    this.setState({ currentUser: currentUser, userReady: true });
    console.log("currentUser.roles :: " + currentUser?.roles);
  }

  saveOrUpdatePassword = (e) => {
    e.preventDefault();
    if (this.state.password === "") {
      this.setState({ passwordval: "Invalid!, Please enter valid password" });
      return false;
    }

    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      this.setState({ redirect: "/login" });
    }

    console.log("changePassword => " + JSON.stringify(currentUser));
    let user = { id: currentUser.id, password: this.state.password };
    console.log("user => " + JSON.stringify(user));

    // step 5
    AuthService.changePassword(currentUser.id, user).then((res) => {
      // alert(res.data);
      this.props.history.push("/login");
    });
  };


  

  changePasswordHandler = (event) => {
    this.setState({ password: event.target.value });
  };
  changeConfirmPasswordHandler = (event) => {
    this.setState({ confirmPassword: event.target.value });
    if (this.state.password === event.target.value) {
      this.setState({ confirmPasswordVal: "Both passwords are same" });
    } else {
      this.setState({ confirmPasswordVal: "Both passwords are not same" });
    }
  };

  cancel() {
    const currentUser = AuthService.getCurrentUser();

    if (currentUser?.roles.includes("ROLE_ADMIN")) {
      this.props.history.push("/dashboard");
    } else if (currentUser?.roles.includes("ROLE_STORE")) {
      this.props.history.push("/dashboardStore");
    }
  }

  getTitle() {
    return (
      <h6 className="m-0 font-weight-bold text-primary">Change Password</h6>
    );
  }
  render() {
    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
 {this.state.currentUser?.roles.includes("ROLE_ADMIN") && (
          <SideBarComponent />
        )}
        {this.state.currentUser?.roles.includes("ROLE_STORE") && (
          <SideBarStoreComponent />
        )}
        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">city</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      {/* <a
                        className="btn btn-sm btn-primary float-right   mr-3"
                        href="/Passwords"
                        role="button"
                      >
                        List Password
                      </a> */}

                      {this.getTitle()}
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="card col-md-6 offset-md-3 offset-md-3">
                          <div className="card-body">
                            <form>
                              <div className="form-group">
                                <label> New Password: </label>

                                <input
                                  placeholder="Password"
                                  type="password"
                                  name="password"
                                  className="form-control"
                                  value={this.state.password}
                                  onChange={this.changePasswordHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.passwordVAL}
                                </p>
                              </div>
                              <div className="form-group">
                                <label> Confirm Password: </label>
                                <input
                                  type="password"
                                  placeholder="Password"
                                  name="confirmPassword"
                                  className="form-control"
                                  value={this.state.confirmPassword}
                                  onChange={this.changeConfirmPasswordHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.confirmPasswordVal}
                                </p>
                              </div>

                              <button
                                className="btn btn-success"
                                onClick={this.saveOrUpdatePassword}
                              >
                                Save
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={this.cancel.bind(this)}
                                style={{ marginLeft: "10px" }}
                              >
                                Cancel
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default ChangePasswordComponent;

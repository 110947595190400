import React, { Component } from 'react'
import FooterComponent from './FooterComponent';


class ContactComponent extends Component {




  render() {

    return (
      <div>
        <header>
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <a className="navbar-brand" href="/"><img src='/images/logo.jpeg' width="70px" ></img></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">




                <li className="nav-item">
                  <a className="nav-link" href="/about-us">About Us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact-us">Contact Us</a>
                </li>


                {/* <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle " href="#!" id="navbarDropdownMenuLink" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Dropdown link
            </a>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
              <a className="dropdown-item" href="#!">Action</a>
              <a className="dropdown-item" href="#!">Another action</a>
              <a className="dropdown-item" href="#!">Something else here</a>
            </div>
          </li> */}

                {/* <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink-4" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false"><i className="fa fa-user"></i> Profile </a>
            <div className="dropdown-menu dropdown-menu-right dropdown-cyan" aria-labelledby="navbarDropdownMenuLink-4">
              <a className="dropdown-item" href="#!">My account</a>
              <a className="dropdown-item" href="#!">Log out</a>
            </div>
          </li> */}
              </ul>


              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link" href="/login">Login</a>

                  {/*
            <Link to="/login" className="nav-link"> Login </Link> */}

                </li>

              </ul>
            </div>
          </nav>

        </header>

        <div id="demo" className="carousel slide" data-ride="carousel">

          {/*
    <!-- Indicators --> */}
          {/* <ul className="carousel-indicators">
      <li data-target="#demo" data-slide-to="0" className="active"></li>
      <li data-target="#demo" data-slide-to="1"></li>
      <li data-target="#demo" data-slide-to="2"></li>
    </ul> */}

          {/*
    <!-- The slideshow --> */}
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="/images/slider1.jpg" alt="Los Angeles" className="img-fluid" width="100%"
                height="500" />
            </div>
            {/* <div className="carousel-item">
        <img src="/images/ezgif.com-gif-maker-2.jpg" alt="Chicago" className="img-fluid" width="100%" height="500" />
      </div> */}
            {/* <div className="carousel-item">
        <img src="/images/car-3.jpg" alt="New York" className="img-fluid" width="100%" height="500" />
      </div> */}
          </div>

          {/*
    <!-- Left and right controls --> */}
          {/* <a className="carousel-control-prev" href="#demo" data-slide="prev">
      <span className="carousel-control-prev-icon"></span>
    </a>
    <a className="carousel-control-next" href="#demo" data-slide="next">
      <span className="carousel-control-next-icon"></span>
    </a> */}
        </div>
        <br></br>




        <div className="container">
          <h3 className='text-center'><b>Contact Form</b></h3>
          <div className='row'>

            <div className="col-12 col mt-md-4">

              <div className="card">
                <div className="card-body">
                  <p className='card-text pl-5'>For We believe in transparent communication and have built various touch points through which our Customers can
                    get in touch with us.Please leave us your opinions about BSECARS.The product and price may differ from region to
                    region. any assistanal Toll Free N umber -1800 209 8282</p>
                </div>
              </div>
            </div>
          </div>

          <hr></hr>




          <div className="row">
            <div className="col-6">

              <form action="/action_page.php">
                <label for="fname">First Name</label>
                <input type="text" id="fname" name="firstname" placeholder="Your name.." />

                <label for="lname">Last Name</label>
                <input type="text" id="lname" name="lastname" placeholder="Your last name.." />

                {/* <label for="country">Country</label>
                <select id="country" name="country">
                  <option value="australia">Australia</option>
                  <option value="canada">Canada</option>
                  <option value="usa">USA</option>
                </select> */}

                <label for="subject">Subject</label>
                <textarea id="subject" name="subject" placeholder="Write something.."></textarea>

                <input type="submit" value="Submit" className="col mt-md-4" />
              </form>
            </div>

            <div className="col-6 col mt-md-4">

              <div className="card">
                <div className="card-body">
                  <h5 className="card-title"><b>SALES</b></h5>
                  <p className="card-text">For any queries related to Product, Price, Dealership, Warranty Please submit your
                    queries on the links given below. Our representative will contact you shortly..</p>
                  <p className="card-text"><b>Call us for Enquiry:-</b> 8007358007.</p>
                  <p className="card-text"><b>Sales Enquiry:- </b>sales@bsecars.com.</p>
                </div>
              </div>
              <br></br>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title"><b>FEEDBACK</b></h5>
                  <p className="card-text">Express your grievances and feedback to executive team regarding our products and
                    service.</p>
                  <p className="card-text"><b>Sales Feedback :-</b>info@bsecars.com</p>
                </div>
              </div>
              <div>
              <div className="footer-social">
                                <a className="btn" href="https://www.facebook.com/BSECARS01/"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn" href="https://www.youtube.com/channel/UCzk4L2kLFC65OGbmZ-yx62A"><i className="fab fa-youtube"></i></a>
                                <a className="btn" href="https://www.instagram.com/bsecars_01/"><i className="fab fa-instagram"></i></a>
                                <a className="btn" href="https://in.linkedin.com/company/bse-cars"><i className="fab fa-linkedin-in"></i></a>
                            </div>
              </div>

            </div>


          </div>
        </div>
        <FooterComponent />
      </div>
    )
  }
}

export default ContactComponent
import axios from "axios";

const DEALER_API_BASE_URL = "https://api.richgoldshine.com/quick/api/v1/stores";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && user.accessToken ? user.accessToken : "";

class StoreService {
  getStores() {
    return axios.get(DEALER_API_BASE_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  createStore(store) {
    return axios.post(DEALER_API_BASE_URL, store, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getStoreById(storeId) {
    return axios.get(DEALER_API_BASE_URL + "/" + storeId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  updateStore(store, storeId) {
    return axios.put(DEALER_API_BASE_URL + "/" + storeId, store, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  deleteStore(storeId) {
    return axios.delete(DEALER_API_BASE_URL + "/" + storeId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  createStoreInquiry(dealerInquiry) {
    return axios.post(DEALER_API_BASE_URL + "/dealerInquiry", dealerInquiry, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getStoresInquiry() {
    return axios.get(DEALER_API_BASE_URL + "/dealerInquiry", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getStoreByBrandId(brandId) {
    return axios.get(DEALER_API_BASE_URL + "/dealerInquiry" + brandId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  createAvailability(carAvailability) {
    return axios.post(
      DEALER_API_BASE_URL + "/dealerCarAvailability",
      carAvailability,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getCarAvailability() {
    return axios.get(DEALER_API_BASE_URL + "/carAvailability", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default new StoreService();

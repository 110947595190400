import React, { Component } from "react";
import AuthService from "../services/auth.service";
import { Redirect } from "react-router-dom";

class SideBarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      this.setState({ redirect: "/login" });
    }
    this.setState({ currentUser: currentUser, userReady: true });
    console.log("currentUser" + JSON.stringify(currentUser));
    if (!currentUser?.roles.includes("ROLE_ADMIN")) {
      console.log("currentUser" + JSON.stringify(currentUser.roles));
      this.setState({ redirect: "/noaccess" });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div>
        {/* <!-- Sidebar --> */}
        <ul
          className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
          id="accordionSidebar"
        >
          {/* <!-- Sidebar - Brand --> */}
          <a
            className="sidebar-brand d-flex align-items-center justify-content-center"
            href="/dashboard"
          >
            <div className="sidebar-brand-icon rotate-n-0">
              <i>
                <img
                  className="img-profile rounded-circle"
                  src="/images/quik_delivary_logo.svg"
                ></img>
              </i>
            </div>
            {/* <div className="sidebar-brand-text mx-3">Quick Delivary</div> */}
          </a>

          {/* <!-- Divider --> */}
          <hr className="sidebar-divider my-0" />

          {/* <!-- Nav Item - Dashboard --> */}
          <li className="nav-item active">
            <a className="nav-link" href="/dashboard">
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </a>
          </li>

          {/* <!-- Divider --> */}
          <hr className="sidebar-divider" />

          {/* <!-- Heading --> */}
          <div className="sidebar-heading">Orders</div>

          {/* <!-- Nav Item - Customer Pages Collapse Menu --> */}
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseCustomerPages"
              aria-expanded="true"
              aria-controls="collapseCustomerPages"
            >
              <i className="fa fa-shopping-cart" aria-hidden="true"></i>
              <span>Orders</span>
            </a>
            <div
              id="collapseCustomerPages"
              className="collapse"
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                {/* <a className="collapse-item" href="/add-order/0">Add Order</a> */}
                <a className="collapse-item" href="/orders">
                  List Orders
                </a>
              </div>
            </div>
          </li>
          {this.state.currentUser?.roles === "ROLE_ADMIN" && (
            <SideBarComponent />
          )}
          <hr className="sidebar-divider d-none d-md-block" />

          <div className="sidebar-heading">Admin</div>
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseVendorPages"
              aria-expanded="true"
              aria-controls="collapseVendorPages"
            >
              <i className="fa fa-university" aria-hidden="true"></i>
              <span>Vendor</span>
            </a>
            <div
              id="collapseVendorPages"
              className="collapse"
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <a className="collapse-item" href="/add-vendor/0">
                  Add Vendor
                </a>
                <a className="collapse-item" href="/vendors">
                  List Vendor
                </a>
              </div>
            </div>
          </li>

          {/* <!-- Nav Item - Customer Pages Collapse Menu --> */}
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseStorePages"
              aria-expanded="true"
              aria-controls="collapseStorePages"
            >
              <i className="fa fa-plus-square" aria-hidden="true"></i>
              <span>Store</span>
            </a>
            <div
              id="collapseStorePages"
              className="collapse"
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <a className="collapse-item" href="/add-store/0">
                  Add Store
                </a>
                <a className="collapse-item" href="/stores">
                  List Store
                </a>
              </div>
            </div>
          </li>

          {/* <!-- Nav Item - Customer Pages Collapse Menu --> */}
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseRiderPages"
              aria-expanded="true"
              aria-controls="collapseRiderPages"
            >
              <i className="fa fa-motorcycle" aria-hidden="true"></i>
              <span>Rider</span>
            </a>
            <div
              id="collapseRiderPages"
              className="collapse"
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <a className="collapse-item" href="/add-rider/0">
                  Add Rider
                </a>
                <a className="collapse-item" href="/riders">
                  List Rider
                </a>
              </div>
            </div>
          </li>

          {/* <!-- Nav Item - Customer Pages Collapse Menu --> */}
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseUserPages"
              aria-expanded="true"
              aria-controls="collapseUserPages"
            >
              <i className="fa fa-users" aria-hidden="true"></i>
              <span>User</span>
            </a>
            <div
              id="collapseUserPages"
              className="collapse"
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <a className="collapse-item" href="/add-user/0">
                  Add User
                </a>
                <a className="collapse-item" href="/users">
                  List User
                </a>
              </div>
            </div>
          </li>
          {/* <!-- Divider --> */}
          <hr className="sidebar-divider d-none d-md-block" />
          {/* <!-- Nav Item - Customer Pages Collapse Menu --> */}
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseAdminSettingsPages"
              aria-expanded="true"
              aria-controls="collapseAdminSettingsPages"
            >
              <i className="fa fa-cog" aria-hidden="true"></i>
              <span>Admin Settings</span>
            </a>
            <div
              id="collapseAdminSettingsPages"
              className="collapse"
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <a className="collapse-item" href="/admin-settings">
                  Admin Settings
                </a>
              </div>
            </div>
          </li>
          {/* <!-- Divider --> */}

          {/* <!-- Sidebar Toggler (Sidebar) --> */}
          {/* <div className="text-center d-none d-md-inline">
    <button className="rounded-circle border-0" id="sidebarToggle"></button>
</div> */}
        </ul>
        {/* <!-- End of Sidebar --> */}
      </div>
    );
  }
}

export default SideBarComponent;

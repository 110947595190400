import React, { Component } from "react";
import UserService from "../services/UserService";
import SideBarComponent from "./SideBarComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";
import { faEye, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

class ListUserComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: {},
      users: [],
      columns: [
        {
          name: "User Id",
          selector: (row) => row.id,
          cellExport: (row) => row.id,
          sortable: true,
        },
        {
          name: "User Name",
          selector: (row) => row.username,
          cellExport: (row) => row.username,
          sortable: true,
        },
        {
          name: "User Email",
          selector: (row) => row.email,
          cellExport: (row) => row.email,
          sortable: true,
          width: "auto",
        },
        {
          name: "User Contact",
          selector: (row) => row.mobile,
          cellExport: (row) => row.mobile,
          sortable: true,
        },
        {
          name: "User Role",
          selector: (row) => row.roles[0].name,
          cellExport: (row) =>row.roles[0].name,
          sortable: true,
        },
        {
          name: "Vendor",
          selector: (row) => row.store?.vendor?.vendorName,
          cellExport: (row) => row.store?.vendor?.vendorName,
          sortable: true,
        },
        {
          name: "Store",
          selector: (row) => row.store?.storeName,
          cellExport: (row) => row.store?.storeName,
          sortable: true,
        },
        {
          cell: (row) => (
            <a href={`/view-user/${row["id"]}`}>
              <FontAwesomeIcon icon={faEye} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) => (
            <a href={`/add-user/${row["id"]}`}>
              <FontAwesomeIcon icon={faEdit} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) => (
            <a href="#!" onClick={() => this.clickHandler(row)}>
              <FontAwesomeIcon icon={faTrash} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
    };
    this.addUser = this.addUser.bind(this);
    this.editUser = this.editUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  deleteUser(id) {
    UserService.deleteUser(id).then((res) => {
      this.setState({
        user: this.state.users.filter((user) => user.userId !== id),
      });
    });
    this.componentDidMount();
  }

  clickHandler = (row) => {
    this.deleteUser(row.id);
  };

  viewUser(id) {
    this.props.history.push(`/view-user/${id}`);
  }
  editUser(id) {
    this.props.history.push(`/add-user/${id}`);
  }

  addUserUser(id) {
    this.props.history.push(`/add-user-user/${id}`);
  }

  componentDidMount() {
    UserService.getUsers().then((res) => {
      //console.log(res.data);
      this.setState({ users: res.data });
      this.setState({
        tableData: { columns: this.state.columns, data: res.data },
      });
    });
  }

  addUser() {
    this.props.history.push("/add-user/0");
  }

  render() {
    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">User</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">
                        List User
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={this.addUser}
                        >
                          {" "}
                          Add User
                        </button>
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        {/* <DataTable
                          columns={this.state.columns}
                          data={this.state.users}
                          filter={true}
                          pagination
                        /> */}

                        <DataTableExtensions {...this.state.tableData}>
                          <DataTable
                            columns={this.state.columns}
                            data={this.state.users}
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover
                            selectableRows
                            print={false}
                            //exportHeaders={false}
                            onSelectedRowsChange={this.handleChange}
                            selectedRows={this.handleChange}
                          />
                        </DataTableExtensions>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default ListUserComponent;

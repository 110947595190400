import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import RiderService from "../services/RiderService";
import SideBarComponent from "./SideBarComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";

class CreateRiderComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // step 2
      id: this.props.match.params.id,
      fullname: "",
      fullNameVal: "",
      address: "",
      addressVal: "",
      aadhar: "",
      aadharVal: "",
      mobile: "",
      mobileVal: "",
      postalCode: "",
      vendors: [],
      vendorId: 0,
    };
    this.changeFullnameHandler = this.changeFullnameHandler.bind(this);
    this.saveOrUpdateRider = this.saveOrUpdateRider.bind(this);
  }

  // step 3
  componentDidMount() {
    // step 4
    if (this.state.id === "0") {
      return;
    } else {
      RiderService.getRiderById(this.state.id).then((res) => {
        let rider = res.data;

        this.setState({
          fullname: rider.fullname,
          mobile: rider.mobile,
          address: rider.address,
          aadhar: rider.aadhar,
        });
      });
    }
  }
  saveOrUpdateRider = (e) => {
    e.preventDefault();
    if (this.state.fullname === "") {
      this.setState({ fullNameVal: "Invalid!, Please enter Full Name" });
      return false;
    } else {
      this.setState({ fullNameVal: "" });
    }

    if (this.state.mobile === "") {
      this.setState({ mobileVal: "Invalid!, Please enter Contact Number" });
      return false;
    } else {
      this.setState({ mobileVal: "" });
    }
    if (this.state.address === "") {
      this.setState({ addressVal: "Invalid!, Please enter Address" });
      return false;
    } else {
      this.setState({ addressVal: "" });
    }
    if (this.state.aadhar === "") {
      this.setState({ aadharVal: "Invalid!, Please enter Aadhar Number" });
      return false;
    } else {
      this.setState({ aadharVal: "" });
    }

    let rider = {
      fullname: this.state.fullname,
      mobile: this.state.mobile,
      address: this.state.address,
      aadhar: this.state.aadhar,
      postalCode: this.state.postalCode,
    };
    console.log("rider => " + JSON.stringify(rider));

    // step 5
    if (this.state.id === "0") {
      RiderService.createRider(rider).then((res) => {
        this.props.history.push("/riders");
      });
    } else {
      RiderService.updateRider(rider, this.state.id).then((res) => {
        this.props.history.push("/riders");
      });
    }
  };

  changeFullnameHandler = (event) => {
    this.setState({ fullname: event.target.value });
  };

  changeAddressHandler = (event) => {
    this.setState({ address: event.target.value });
  };

  changeMobileHandler = (event) => {
    this.setState({ mobile: event.target.value });
  };

  changeAadharHandler = (event) => {
    this.setState({ aadhar: event.target.value });
  };

  cancel() {
    this.props.history.push("/Riders");
  }

  getTitle() {
    if (this.state.id === "0") {
      return <h6 className="m-0 font-weight-bold text-primary">Add Rider</h6>;
    } else {
      return (
        <h6 className="m-0 font-weight-bold text-primary">Update Rider</h6>
      );
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    //     const { currentUser } = this.state;

    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Rider</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <a
                        className="btn btn-sm btn-primary float-right   mr-3"
                        href="/Riders"
                        role="button"
                      >
                        List Rider
                      </a>

                      {this.getTitle()}
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="card col-md-6 offset-mdvendorLatitude-3 offset-md-3">
                          <div className="card-body">
                            <form>
                              <div className="form-group">
                                <label> Rider Name: </label>
                                <input
                                  placeholder="Rider Name"
                                  name="fullname"
                                  className="form-control"
                                  value={this.state.fullname}
                                  onChange={this.changeFullnameHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.fullNameVal}
                                </p>
                              </div>

                              <div className="form-group">
                                <label> Rider Mobile: </label>
                                <input
                                  placeholder="Rider Mobile"
                                  maxLength="11"
                                  name="mobile"
                                  className="form-control"
                                  value={this.state.mobile}
                                  onChange={this.changeMobileHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.mobileVal}
                                </p>
                              </div>

                              <div className="form-group">
                                <label> Rider Address: </label>
                                <input
                                  placeholder="Rider Address"
                                  name="address"
                                  className="form-control"
                                  value={this.state.address}
                                  onChange={this.changeAddressHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.addressVal}
                                </p>
                              </div>

                              <div className="form-group">
                                <label> Rider Aadhar: </label>
                                <input
                                  placeholder="Rider Aadhar"
                                  maxLength="12"
                                  name="aadhar"
                                  className="form-control"
                                  value={this.state.aadhar}
                                  onChange={this.changeAadharHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.aadharVal}
                                </p>
                              </div>
                              <button
                                className="btn btn-success"
                                onClick={this.saveOrUpdateRider}
                              >
                                Save
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={this.cancel.bind(this)}
                                style={{ marginLeft: "10px" }}
                              >
                                Cancel
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default CreateRiderComponent;

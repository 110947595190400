import React from "react";

import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginComponent from "./components/LoginComponent";
import RegisterComponent from "./components/RegisterComponent";
import DashboardComponent from "./components/DashboardComponent";
import AboutUsComponent from "./components/AboutUsComponent";
import ContactComponent from "./components/ContactComponent";
import ForgotComponent from "./components/ForgotComponent";
import SideBarStoreComponent from "./components/SideBarStoreComponent";

import PrivacyPolicyComponent from "./components/PrivacyPolicyComponent";
import TermsAndConditionComponent from "./components/TermsAndConditionComponent";
import DashboardStoreComponent from "./components/DashboardStoreComponent";
import CreateVendorComponent from "./components/CreateVendorComponent";
import ListVendorComponent from "./components/ListVendorComponent";
import ViewVendorComponent from "./components/ViewVendorComponent";
import CreateOrderComponent from "./components/CreateOrderComponent";
import ListOrderComponent from "./components/ListOrderComponent";
import ListStoreOrderReportComponent from "./components/ListStoreOrderReportComponent";

import ViewOrderComponent from "./components/ViewOrderComponent";
import CreateUserComponent from "./components/CreateUserComponent";
import ListUserComponent from "./components/ListUserComponent";
import ViewUserComponent from "./components/ViewUserComponent";

import CreateStoreComponent from "./components/CreateStoreComponent";
import ListStoreComponent from "./components/ListStoreComponent";
import ViewStoreComponent from "./components/ViewStoreComponent";
import CreateRiderComponent from "./components/CreateRiderComponent";
import ListRiderComponent from "./components/ListRiderComponent";
import ViewRiderComponent from "./components/ViewRiderComponent";
import ListRiderOrderComponent from "./components/ListRiderOrderComponent";
import ListRiderAttendanceComponent from "./components/ListRiderAttendanceComponent";
import NoAccessComponent from "./components/NoAccessComponent";
import ListVendorOrderComponent from "./components/ListVendorOrderComponent";
import AdminSettingsComponent from "./components/AdminSettingsComponent";
import ChangePasswordComponent from "./components/ChangePasswordComponent";
import ViewRiderAttendanceComponent from "./components/ViewRiderAttendanceComponent";

function App() {
  return (
    <div>
      <Router>
        <div className="container-fluid">
          {/* <HeaderComponent /> */}

          <Switch>
            {/* <Route path="/" exact component={IndexComponent}></Route> */}
            <Route path="/" exact component={LoginComponent}></Route>
            <Route path="/login" exact component={LoginComponent}></Route>
            <Route path="/noaccess" exact component={NoAccessComponent}></Route>

            <Route path="/register" exact component={RegisterComponent}></Route>
            <Route path="/dashboard" component={DashboardComponent}></Route>
            <Route
              path="/dashboardStore"
              component={DashboardStoreComponent}
            ></Route>

            <Route path="/about-us" component={AboutUsComponent}></Route>
            <Route path="/contact-us" component={ContactComponent}></Route>
            <Route path="/forgot" exact component={ForgotComponent}></Route>
            <Route
              path="/change-password"
              exact
              component={ChangePasswordComponent}
            ></Route>
            <Route path="/1" exact component={SideBarStoreComponent}></Route>

            <Route
              path="/privacy-policy"
              exact
              component={PrivacyPolicyComponent}
            ></Route>
            <Route
              path="/terms-and-condition"
              exact
              component={TermsAndConditionComponent}
            ></Route>

            <Route
              path="/add-vendor/:id"
              component={CreateVendorComponent}
            ></Route>
            <Route path="/vendors" component={ListVendorComponent}></Route>
            <Route
              path="/view-vendor/:id"
              component={ViewVendorComponent}
            ></Route>

            <Route
              path="/add-order/:id"
              component={CreateOrderComponent}
            ></Route>
            <Route path="/orders" component={ListOrderComponent}></Route>

            <Route
              path="/store-order-report/:id"
              component={ListStoreOrderReportComponent}
            ></Route>
            <Route
              path="/ordersByStatus/:status"
              component={ListOrderComponent}
            ></Route>

            <Route
              path="/view-order/:id"
              component={ViewOrderComponent}
            ></Route>

            <Route
              path="/add-store/:id"
              component={CreateStoreComponent}
            ></Route>
            <Route path="/stores" component={ListStoreComponent}></Route>
            <Route
              path="/view-store/:id"
              component={ViewStoreComponent}
            ></Route>

            <Route path="/add-user/:id" component={CreateUserComponent}></Route>
            <Route path="/users" component={ListUserComponent}></Route>
            <Route path="/view-user/:id" component={ViewUserComponent}></Route>

            <Route
              path="/add-rider/:id"
              component={CreateRiderComponent}
            ></Route>
            <Route path="/riders" component={ListRiderComponent}></Route>
            <Route
              path="/riders-today/:status"
              component={ListRiderComponent}
            ></Route>

            <Route
              path="/view-rider/:id"
              component={ViewRiderComponent}
            ></Route>
            <Route
              path="/rider-orders/:id"
              component={ListRiderOrderComponent}
            ></Route>

            <Route
              path="/rider-attendance/:id"
              component={ListRiderAttendanceComponent}
            ></Route>

            <Route
              path="/vendor-orders/:id"
              component={ListVendorOrderComponent}
            ></Route>

            <Route
              path="/view-rider-attendance/:id"
              component={ViewRiderAttendanceComponent}
            ></Route>
            <Route
              path="/admin-settings"
              component={AdminSettingsComponent}
            ></Route>
          </Switch>
          {/* <FooterComponent /> */}
        </div>
      </Router>
    </div>
  );
}

export default App;

import React, { Component } from "react";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import HeaderComponent from "./HeaderComponent";

import UserService from "../services/UserService";
import FooterComponent from "./FooterComponent";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class LoginComponent extends Component {
  constructor(props) {
    super(props);

    this.forgotPassword = this.forgotPassword.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      email: "",
      password: "",
      loading: false,
      message: "",
    };
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  saveOrUpdateUser = (e) => {
    e.preventDefault();
    let user = {
      id: this.state.id,
      username: this.state.username,
      email: this.state.email,
      mobile: this.state.mobile,
      password: this.state.password,
      store: { storeId: this.state.storeId },
    };
    console.log("user => " + JSON.stringify(user));

    // step 5
    if (this.state.id === "0") {
      UserService.createUser(user).then((res) => {
        this.props.history.push("/users");
      });
    } else {
      UserService.updateUser(user, this.state.id).then((res) => {
        this.props.history.push("/users");
      });
    }
  };

  forgotPassword(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      let user = {
        email: this.state.email,
      };

      UserService.forgotPassword(user).then((res) => {
        console.log("user => " + JSON.stringify(res));
        alert("Email sent on "+ res.data.email+ " !!! Please login with new password.");
        this.props.history.push("/login");
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  // deleteEmployee(id){
  //     EmployeeService.deleteEmployee(id).then( res => {
  //         this.setState({employees: this.state.employees.filter(employee => employee.id !== id)});
  //     });
  // }
  // viewEmployee(id){
  //     this.props.history.push(`/view-employee/${id}`);
  // }
  // editEmployee(id){
  //     this.props.history.push(`/add-employee/${id}`);
  // }

  // componentDidMount(){
  //     EmployeeService.getEmployees().then((res) => {
  //         this.setState({ employees: res.data});
  //     });
  // }

  // addEmployee(){
  //     this.props.history.push('/add-employee/0');
  // }

  // viewCustomer(){
  //     this.props.history.push('/customers');
  // }

  render() {
    return (
      <div>
        <HeaderComponent />

        <h2 className="text-center">Forgot Password</h2>

        <div className="container">
          {/* <!-- Outer Row --> */}
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  {/* <!-- Nested Row within Card Body --> */}
                  <div className="row">
                    <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                    <div className="col-lg-6">
                      <div className="p-5">
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                        </div>

                        <Form
                          onSubmit={this.forgotPassword}
                          ref={(c) => {
                            this.form = c;
                          }}
                        >
                          {/* <form className="user"> */}

                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="exampleInputEmail"
                              aria-describedby="emailHelp"
                              value={this.state.email}
                              onChange={this.onChangeEmail}
                              validations={[required]}
                              placeholder="Enter Email Address..."
                              name="email"
                            />
                          </div>

                          {/* <a href="index.html" className="btn btn-primary btn-user btn-block">
                                            Login
                                        </a> */}

                          {/* </form> */}

                          <div className="form-group">
                            <button
                              className="btn btn-primary btn-block"
                              disabled={this.state.loading}
                            >
                              {this.state.loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                              )}
                              <span>Forgot Password</span>
                            </button>
                          </div>

                          {this.state.message && (
                            <div className="form-group">
                              <div className="alert alert-danger" role="alert">
                                {this.state.message}
                              </div>
                            </div>
                          )}
                          <CheckButton
                            style={{ display: "none" }}
                            ref={(c) => {
                              this.checkBtn = c;
                            }}
                          />
                        </Form>
                        <hr />
                        <div className="text-center">
                          <a className="small" href="/login">
                            Login
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </div>
    );
  }
}

export default LoginComponent;

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import SideBarComponent from "./SideBarComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";
import AuthService from "../services/auth.service";
import AdminSettingsService from "../services/AdminSettingsService";

class AdminSettingsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // step 2
      currentUser: null,
      id: 1,
      riderRadiusDistance: 0,
      perKMRiderRate: 0,
      fixedRiderRate: 0,
      fixedRiderKMs: 0,
      perKMVendorRate: 0,
      fixedVendorRate: 0,
      fixedVendorKMs: 0,
    };
    // this.changeOrderNameHandler = this.changeOrderNameHandler.bind(this);
    this.saveOrUpdateAdminSettings = this.saveOrUpdateAdminSettings.bind(this);
  }

  // step 3
  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      this.setState({ redirect: "/login" });
    }
    this.setState({ currentUser: currentUser, userReady: true });
    console.log("currentUser" + JSON.stringify(currentUser?.roles));

    if (!currentUser?.roles?.includes("ROLE_ADMIN")) {
      console.log("currentUser" + JSON.stringify(currentUser.roles));
      this.setState({ redirect: "/noaccess" });
    }

    if (this.state.id === "0") {
      return;
    } else {
      AdminSettingsService.getAdminSettingsById(this.state.id).then((res) => {
        let adminSettings = res.data;
        console.log("Admin Settings ", res.data);
        this.setState({
          riderRadiusDistance: adminSettings.riderRadiusDistance,
          perKMRiderRate: adminSettings.perKMRiderRate,
          fixedRiderRate: adminSettings.fixedRiderRate,
          fixedRiderKMs: adminSettings.fixedRiderKMs,
          perKMVendorRate: adminSettings.perKMVendorRate,
          fixedVendorRate: adminSettings.fixedVendorRate,
          fixedVendorKMs: adminSettings.fixedVendorKMs,
        });
      });
    }
  }
  saveOrUpdateAdminSettings = (e) => {
    e.preventDefault();

    let adminSettings = {
      id: this.state.id,
      riderRadiusDistance: this.state.riderRadiusDistance,
      perKMRiderRate: this.state.perKMRiderRate,
      fixedRiderRate: this.state.fixedRiderRate,
      fixedRiderKMs: this.state.fixedRiderKMs,
      perKMVendorRate: this.state.perKMVendorRate,
      fixedVendorRate: this.state.fixedVendorRate,
      fixedVendorKMs: this.state.fixedVendorKMs,
    };

    // step 5
    if (this.state.id === 1) {
      AdminSettingsService.updateAdminSettings(
        adminSettings,
        this.state.id
      ).then((res) => {
        alert("Settings Saved Successfully!!!");
        this.props.history.push("/admin-settings");
      });
    }
  };

  changeRiderRadiusDistanceHandler = (event) => {
    this.setState({ riderRadiusDistance: event.target.value });
  };

  changePerKMRiderRateHandler = (event) => {
    this.setState({ perKMRiderRate: event.target.value });
  };

  changecustomerNameHandler = (event) => {
    this.setState({ customerName: event.target.value });
  };
  changemobileNumberHandler = (event) => {
    this.setState({ mobileNumber: event.target.value });
  };

  changeFixedRiderRateHandler = (event) => {
    this.setState({ fixedRiderRate: event.target.value });
  };

  changeFixedRiderKMsHandler = (event) => {
    this.setState({ fixedRiderKMs: event.target.value });
  };
  changePerKMVendorRateHandler = (event) => {
    this.setState({ perKMVendorRate: event.target.value });
  };
  changeFixedVendorRateHandler = (event) => {
    this.setState({ fixedVendorRate: event.target.value });
  };
  changeFixedVendorKMsHandler = (event) => {
    this.setState({ fixedVendorKMs: event.target.value });
  };

  cancel() {
    this.props.history.push("/dashboard");
  }

  getTitle() {
    if (this.state.id === "0") {
      return <h6 className="m-0 font-weight-bold text-primary">Add Order</h6>;
    } else {
      return (
        <h6 className="m-0 font-weight-bold text-primary">
          Update Admin Settings
        </h6>
      );
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    //     const { currentUser } = this.state;

    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        {this.state.currentUser?.roles?.includes("ROLE_ADMIN") && (
          <SideBarComponent />
        )}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Order</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">{this.getTitle()}</div>
                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="card col-md-6">
                            <h3 className="text-center"> Rider Details</h3>

                            <div className="card-body">
                              <div className="form-group">
                                <label> Rider Radius Distance(KMs): </label>
                                <input
                                  placeholder="Rider Radius Distance"
                                  name="riderRadiusDistance"
                                  className="form-control"
                                  value={this.state.riderRadiusDistance}
                                  onChange={
                                    this.changeRiderRadiusDistanceHandler
                                  }
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.orderNumberVal}
                                </p>
                              </div>

                              <div className="form-group">
                                <label> Per KM Rider Rate: </label>
                                <input
                                  placeholder="Per KM Rider Rate"
                                  name="perKMRiderRate"
                                  className="form-control"
                                  value={this.state.perKMRiderRate}
                                  onChange={this.changePerKMRiderRateHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.orderValueVal}
                                </p>
                              </div>

                              <div className="form-group">
                                <label> Fixed Rider Rate: </label>
                                <input
                                  placeholder="Fixed Rider Rate"
                                  name="fixedRiderRate"
                                  className="form-control"
                                  value={this.state.fixedRiderRate}
                                  onChange={this.changeFixedRiderRateHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.customerAddressVal}
                                </p>
                              </div>
                              <div className="form-group">
                                <label> Fixed Rider KMs: </label>
                                <input
                                  placeholder="Fixed Rider KMs"
                                  maxLength="11"
                                  name="fixedRiderKMs"
                                  className="form-control"
                                  value={this.state.fixedRiderKMs}
                                  onChange={this.changeFixedRiderKMsHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.mobileNumberVal}
                                </p>
                              </div>
 <button
                                className="btn btn-success"
                                onClick={this.saveOrUpdateAdminSettings}
                              >
                                Save
                              </button>

                              <button
                                className="btn btn-danger"
                                onClick={this.cancel.bind(this)}
                                style={{ marginLeft: "10px" }}
                              >
                                Cancel
                              </button>


                              
                            </div>
                          </div>

                          {/* <div className="card col-md-6">
                            <h3 className="text-center"> Vendor Details</h3>

                            <div className="card-body">
                              <div className="form-group">
                                <label> Per KM Vendor Rate: </label>
                                <input
                                  placeholder="Per KM Vendor Rate"
                                  maxLength="11"
                                  name="perKMVendorRate"
                                  className="form-control"
                                  value={this.state.perKMVendorRate}
                                  onChange={this.changePerKMVendorRateHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.mobileNumberVal}
                                </p>
                              </div>
                              <div className="form-group">
                                <label> Fixed Vendor Rate: </label>
                                <input
                                  placeholder="Fixed Vendor Rate"
                                  maxLength="11"
                                  name="fixedVendorRate"
                                  className="form-control"
                                  value={this.state.fixedVendorRate}
                                  onChange={this.changeFixedVendorRateHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.mobileNumberVal}
                                </p>
                              </div>
                              <div className="form-group">
                                <label> Fixed Vendor KMs: </label>
                                <input
                                  placeholder="Fixed Vendor KMs"
                                  maxLength="11"
                                  name="fixedVendorKMs"
                                  className="form-control"
                                  value={this.state.fixedVendorKMs}
                                  onChange={this.changeFixedVendorKMsHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.mobileNumberVal}
                                </p>
                              </div>
                              <button
                                className="btn btn-success"
                                onClick={this.saveOrUpdateAdminSettings}
                              >
                                Save
                              </button>

                              <button
                                className="btn btn-danger"
                                onClick={this.cancel.bind(this)}
                                style={{ marginLeft: "10px" }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div> */}
                        </div>
                      </form>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default AdminSettingsComponent;

import axios from "axios";

const DEALER_API_BASE_URL =
  "https://api.richgoldshine.com/quick/api/v1/vendors";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && user.accessToken ? user.accessToken : "";

class VendorService {
  getVendors() {
    return axios.get(DEALER_API_BASE_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  createVendor(vendor) {
    return axios.post(DEALER_API_BASE_URL, vendor, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getVendorById(vendorId) {
    return axios.get(DEALER_API_BASE_URL + "/" + vendorId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  updateVendor(vendor, vendorId) {
    return axios.put(DEALER_API_BASE_URL + "/" + vendorId, vendor, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  deleteVendor(vendorId) {
    return axios.delete(DEALER_API_BASE_URL + "/" + vendorId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  createVendorInquiry(dealerInquiry) {
    return axios.post(DEALER_API_BASE_URL + "/dealerInquiry", dealerInquiry, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getVendorsInquiry() {
    return axios.get(DEALER_API_BASE_URL + "/dealerInquiry", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getVendorByBrandId(brandId) {
    return axios.get(DEALER_API_BASE_URL + "/dealerInquiry" + brandId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  createAvailability(carAvailability) {
    return axios.post(
      DEALER_API_BASE_URL + "/dealerCarAvailability",
      carAvailability,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getCarAvailability() {
    return axios.get(DEALER_API_BASE_URL + "/carAvailability", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default new VendorService();

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import UserService from "../services/UserService";
import StoreService from "../services/StoreService";
import SideBarComponent from "./SideBarComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";

class CreateUserComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // step 2
      id: this.props.match.params.id,
      username: "",usernameVal: "",
      email: "", emailVal: "",
      mobile: "",mobileVal: "",
      password: "", passwordVal: "",
      stores: [], 
      storeId: 0,  
      currentUser: null,
    };
    this.changeUserNameHandler = this.changeUserNameHandler.bind(this);
    this.saveOrUpdateUser = this.saveOrUpdateUser.bind(this);
  }

  // step 3
  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      this.setState({ redirect: "/login" });
    }
    this.setState({ currentUser: currentUser, userReady: true });
    console.log("currentUser" + JSON.stringify(currentUser?.roles));

    if (!currentUser?.roles.includes("ROLE_ADMIN")) {
      console.log("currentUser" + JSON.stringify(currentUser.roles));
      this.setState({ redirect: "/noaccess" });
    }
    StoreService.getStores().then((res) => {
      this.setState({ stores: res.data });
    });

    UserService.getUsers().then((res) => {
      this.setState({ users: res.data });
    });

    // step 4
    if (this.state.id === "0") {
      return;
    } else {
      UserService.getUserById(this.state.id).then((res) => {
        let user = res.data;

        this.setState({
          id: user.id,
          username: user.username,
          email: user.email,
          mobile: user.mobile,
          password: user.password,
          storeId: user.store?.storeId,
        });
      });
    }
  }
  saveOrUpdateUser = (e) => {
    e.preventDefault();
    if (this.state.username === "") {
      this.setState({ usernameVal: "Invalid!, Please enter User Name" });
      return false;
    }
    else {
      this.setState({ usernameVal: "" });
    }
    
    if (this.state.email === "") {
      this.setState({ emailVal: "Invalid!, Please enter Email" });
      return false;
    }
    else {
      this.setState({ emailVal: "" });
    }
    if (this.state.password === "") {
      this.setState({ passwordVal: "Invalid!, Please enter Password" });
      return false;
    }
    else {
      this.setState({ passwordVal: "" });
    }
    if (this.state.mobile === "") {
      this.setState({ mobileVal: "Invalid!, Please enter Mobile Number" });
      return false;
    }
    else {
      this.setState({ mobileVal: "" });
    }




    let user = {
      id: this.state.id,
      username: this.state.username,
      email: this.state.email,
      mobile: this.state.mobile,
      password: this.state.password,
      store: { storeId: this.state.storeId },
    };
    console.log("user => " + JSON.stringify(user));

    // step 5
    if (this.state.id === "0") {
      UserService.createUser(user).then((res) => {
        this.props.history.push("/users");
      });
    } else {
      UserService.updateUser(user, this.state.id).then((res) => {
        this.props.history.push("/users");
      });
    }
  };

  changeUserNameHandler = (event) => {
    this.setState({ username: event.target.value });
  };
  changeStoreIdHandler = (event) => {
    this.setState({ storeId: event.target.value });
  };

  changeMobileHandler = (event) => {
    this.setState({ mobile: event.target.value });
  };

  changeEmailHandler = (event) => {
    this.setState({ email: event.target.value });
  };
  changePasswordHandler = (event) => {
    this.setState({ password: event.target.value });
  };

  cancel() {
    this.props.history.push("/users");
  }

  getTitle() {
    if (this.state.id === "0") {
      return <h6 className="m-0 font-weight-bold text-primary">Add User</h6>;
    } else {
      return <h6 className="m-0 font-weight-bold text-primary">Update User</h6>;
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">User</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <a
                        className="btn btn-sm btn-primary float-right   mr-3"
                        href="/Users"
                        role="button"
                      >
                        List User
                      </a>

                      {this.getTitle()}
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="card col-md-6 offset-md-3 offset-md-3">
                          <div className="card-body">
                            <form>
                              <div className="form-group">
                                <label> User Name: </label>
                                <input
                                  placeholder="User Name"
                                  name="username"
                                  className="form-control"
                                  value={this.state.username}
                                  onChange={this.changeUserNameHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.usernameVal}
                                </p>


                              </div>

                              <div className="form-group">
                                <label> Store: </label>

                                <select
                                  className="form-select col-12"
                                  name="storeId"
                                  aria-label="Default select example"
                                  onChange={this.changeStoreIdHandler}
                                >
                                  <option value="">Select Store</option>

                                  {this.state.stores.map((store) => (
                                    <option
                                      value={store?.storeId}
                                      selected={
                                        this.state.storeId === store?.storeId
                                      }
                                    >
                                      {store?.storeName}
                                    </option>
                                  ))}
                                </select>
                                
                              </div>

                              <div className="form-group">
                                <label> User Email: </label>
                                <input
                                  placeholder="User Email"
                                  name="email"
                                  className="form-control"
                                  value={this.state.email}
                                  onChange={this.changeEmailHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.emailVal}
                                </p>
                                
                              </div>

                              <div className="form-group">
                                <label> User Password: </label>
                                <input
                                  placeholder="User Password"
                                  name="password"
                                  className="form-control"
                                  value={this.state.password}
                                  onChange={this.changePasswordHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.passwordVal}
                                </p>
                              </div>

                              <div className="form-group">
                                <label> User Mobile: </label>
                                <input
                                  placeholder="User Mobile"
                                  maxLength="11"
                                  name="mobile"
                                  className="form-control"
                                  value={this.state.mobile}
                                  onChange={this.changeMobileHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.mobileVal}
                                </p>
                              </div>

                              <button
                                className="btn btn-success"
                                onClick={this.saveOrUpdateUser}
                              >
                                Save
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={this.cancel.bind(this)}
                                style={{ marginLeft: "10px" }}
                              >
                                Cancel
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default CreateUserComponent;

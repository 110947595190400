import axios from "axios";

const ORDER_API_BASE_URL = "https://api.richgoldshine.com/quick/api/v1/orders";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && user.accessToken ? user.accessToken : "";
class OrderService {
  // getOrders(){
  //     return axios.get(ORDER_API_BASE_URL);
  // }

  // getOrdersByDate(dateFilter){
  //     return axios.post(ORDER_API_BASE_URL+'/ordersByDate', dateFilter);
  // }

  createOrder(order) {
    return axios.post(ORDER_API_BASE_URL, order, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getOrderDistance(order) {
    return axios.post(ORDER_API_BASE_URL + "/orderDistance", order, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  vendorOrders(vendorId, orderFilter) {
    return axios.post(
      ORDER_API_BASE_URL + "/vendorOrders/" + vendorId,
      orderFilter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
  riderOrders(riderId, orderFilter) {
    return axios.post(
      ORDER_API_BASE_URL + "/riderOrders/" + riderId,
      orderFilter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getOrdersByStatus(orderFilter) {
    return axios.post(ORDER_API_BASE_URL + "/ordersByStatus", orderFilter, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  getStoreOrdersByStatus(storeId, orderFilter) {
    return axios.post(
      ORDER_API_BASE_URL + "/storeOrdersByStatus/" + storeId,
      orderFilter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getStoreOrdersReport(storeId, orderFilter) {
    return axios.post(
      ORDER_API_BASE_URL + "/storeOrdersReport/" + storeId,
      orderFilter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
  getOrderById(orderId) {
    return axios.get(ORDER_API_BASE_URL + "/" + orderId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  updateOrder(order, orderId) {
    return axios.put(ORDER_API_BASE_URL + "/" + orderId, order, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  deleteOrder(orderId) {
    return axios.delete(ORDER_API_BASE_URL + "/" + orderId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default new OrderService();

import axios from "axios";

const ATTENDANCE_API_BASE_URL =
  "https://api.richgoldshine.com/quick/api/v1/attendance";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && user.accessToken ? user.accessToken : "";
class AttendanceService {
  riderAttendance(riderId) {
    return axios.get(ATTENDANCE_API_BASE_URL + "/byRider/" + riderId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getAttendanceById(attendanceId) {
    return axios.get(ATTENDANCE_API_BASE_URL + "/" + attendanceId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  getRiderLogoutData() {
    return axios.get(ATTENDANCE_API_BASE_URL + "/riderLogoutData", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default new AttendanceService();

import axios from "axios";

const DEALER_API_BASE_URL = "https://api.richgoldshine.com/quick/api/v1/riders";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && user.accessToken ? user.accessToken : "";
class RiderService {
  getRiders() {
    return axios.get(DEALER_API_BASE_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  getTodaysRiders() {
    return axios.get(DEALER_API_BASE_URL + "/today", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getOnlineRiders() {
    return axios.get(DEALER_API_BASE_URL + "/onlineRiders", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getRiderPositions() {
    return axios.get(
      "https://api.richgoldshine.com/quick/api/v1/riderPositions",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  createRider(rider) {
    return axios.post(DEALER_API_BASE_URL, rider, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getRiderById(riderId) {
    return axios.get(DEALER_API_BASE_URL + "/" + riderId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  updateRider(rider, riderId) {
    return axios.put(DEALER_API_BASE_URL + "/" + riderId, rider, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  deleteRider(riderId) {
    return axios.delete(DEALER_API_BASE_URL + "/" + riderId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  blockRider(riderId) {
    return axios.put(DEALER_API_BASE_URL + "/blockRider/" + riderId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
export default new RiderService();

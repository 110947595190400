import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
class FooterComponent extends Component {
  render() {
    const ballStyle = {
      borderLeft: "1px solid FloralWhite",
      height: "150px",
    };
    return (
      <div className="footer-dark">
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-md-6 item text ">
                <h3>Quick Delivary</h3>
                <p className="card-text">
                  For any delivery and partner enquiries in touch with us
                </p>
                <p className="card-text">
                  <b>Call us for Enquiry:-</b> 8007358007.
                </p>
                <p className="card-text">
                  <b>Sales Enquiry:- </b>sales@quickdelivary.com.
                </p>{" "}
              </div>
              <div className="col-sm-6 col-md-3 item" style={ballStyle}>
                <h3>OVERVIEW</h3>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  {/* <li><a href="about-us">About-Us</a></li>
                                <li><a href="contact-us">Contact-Us</a></li> */}
                </ul>
              </div>
              <div className="col-sm-6 col-md-3 item " style={ballStyle}>
                <h3>Other</h3>
                <ul>
                  <li>
                    <a href="/terms-and-condition">Terms & Condition</a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                </ul>
              </div>
              <div className="col item social">
                <a
                  href="https://www.facebook.com/quick-delivary/"
                  className="faFacebook social"
                >
                  {" "}
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCzk4L2kLFC65OGbmZ-yx62A"
                  className="faFacebook social"
                >
                  {" "}
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
                <a
                  href="https://www.twitter.com/quick-delivary/"
                  className="faFacebook social"
                >
                  {" "}
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a
                  href="https://www.instagram.com/quick-delivary/"
                  className="faFacebook social"
                >
                  {" "}
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </div>
            </div>
            <p className="copyright">Quick Delivary © 2022</p>
          </div>
        </footer>
      </div>
    );
  }
}

export default FooterComponent;

import axios from "axios";

const DASHBOARD_API_BASE_URL =
  "https://api.richgoldshine.com/quick/api/v1/dashboard";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && user.accessToken ? user.accessToken : "";
class DashboardService {
  getDashboardData() {
    return axios.get(DASHBOARD_API_BASE_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getStoreDashboardData(storeId) {
    return axios.get(DASHBOARD_API_BASE_URL + "/" + storeId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  createBrand(brand) {
    return axios.post(DASHBOARD_API_BASE_URL, brand, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getBrandById(brandId) {
    return axios.get(DASHBOARD_API_BASE_URL + "/" + brandId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  updateBrand(brand, brandId) {
    return axios.put(DASHBOARD_API_BASE_URL + "/" + brandId, brand, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  deleteBrand(brandId) {
    return axios.delete(DASHBOARD_API_BASE_URL + "/" + brandId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default new DashboardService();

import axios from "axios";

const DEALER_API_BASE_URL = "https://api.richgoldshine.com/quick/api/v1/users";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && user.accessToken ? user.accessToken : "";

class UserService {
  getUsers() {
    return axios.get(DEALER_API_BASE_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  createUser(user) {
    return axios.post(DEALER_API_BASE_URL, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getUserById(userId) {
    return axios.get(DEALER_API_BASE_URL + "/" + userId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  updateUser(user, userId) {
    return axios.put(DEALER_API_BASE_URL + "/" + userId, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  deleteUser(userId) {
    return axios.delete(DEALER_API_BASE_URL + "/" + userId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  forgotPassword(user) {
    return axios.put(DEALER_API_BASE_URL + "/forgot", user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default new UserService();

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import RiderService from "../services/RiderService";
import SideBarComponent from "./SideBarComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";
import AuthService from "../services/auth.service";
import AttendanceService from "../services/AttendanceService";

class ViewRiderAttendanceComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      attendance: {},
      rider: {},

      currentUser: null,
    };

    this.viewRiderAttendance = this.viewRiderAttendance.bind(this);
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      this.setState({ redirect: "/login" });
    }
    this.setState({ currentUser: currentUser, userReady: true });
    console.log("currentUser" + JSON.stringify(currentUser?.roles));

    if (!currentUser?.roles.includes("ROLE_ADMIN")) {
      console.log("currentUser" + JSON.stringify(currentUser.roles));
      this.setState({ redirect: "/noaccess" });
    }
    AttendanceService.getAttendanceById(this.state.id).then((res) => {
      //console.log(res.data);
      this.setState({ attendance: res.data });
      this.setState({ rider: res.data?.rider });
    });
    console.log(this.state.attendance);
  }

  viewRiderAttendance() {
    this.props.history.push("/rider-attendance/" + this.state.rider?.riderId);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Rider</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">
                        View Rider Attendance
                        <button
                          className="btn btn-primary"
                          onClick={this.viewRiderAttendance}
                        >
                          Rider Attendance
                        </button>
                      </h6>
                    </div>
                    <div className="card-body">
                      {/* <h2 className="text-center">Riders List</h2> */}
                      <div className="row">
                        <br></br>

                        <div className="card col-md-6">
                          <h3 className="text-center"> Rider Details</h3>
                          <div className="card-body">
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Rider Id:{" "}
                              </label>{" "}
                              <div> {this.state.rider.riderId}</div>
                            </div>
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Rider Name:{" "}
                              </label>{" "}
                              <div> {this.state.rider.fullname}</div>
                            </div>
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Rider Number:{" "}
                              </label>
                              <div> {this.state.rider.mobile}</div>
                            </div>

                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Rider Aadhar:{" "}
                              </label>
                              <div> {this.state.rider.aadhar}</div>
                            </div>
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Rider Address:{" "}
                              </label>
                              <div> {this.state.rider.address}</div>
                            </div>
                          </div>
                        </div>
                        <div className="card col-md-6">
                          <h3 className="text-center"> Attendance Details</h3>
                          <div className="card-body">
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Login Date Time:
                              </label>
                              <div>
                                {" "}
                                {this.state.attendance.attendanceDateTime}
                              </div>
                            </div>
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Logout Date Time:
                              </label>
                              <div>
                                {" "}
                                {this.state.attendance.attendanceOutDateTime}
                              </div>
                            </div>
                            <div className="row">
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Login Selfie:
                              </label>
                              <div>
                                {" "}
                                <img
                                  src={
                                    " https://api.richgoldshine.com/quick/api/v1/attendance/image/" +
                                    this.state.attendance.attendanceId
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default ViewRiderAttendanceComponent;

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import DashboardService from "../services/DashboardService";
import Table from "react-bootstrap/Table";
import SideBarComponent from "../components/SideBarComponent";
import AttendanceService from "../services/AttendanceService";
import RiderService from "../services/RiderService";

import '../App.css';
import BackEndFooterComponent from "../components/BackEndFooterComponent";
import TopBarComponent from "../components/TopBarComponent";

class DashboardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      userReady: false,
      currentUser: { username: "" },
      totalOrders: 0,
      openOrders: 0,
      totalStores: 0,
      totalVendors: 0,
      acceptedOrders: 0,
      deliveredOrders: 0,
      totalRiders: 0,
      todaysOrders: 0,
      todaysAvailableRiders: 0,
      logoutRiders: [],
      onlineRiders: [],
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) this.setState({ redirect: "/login" });
    this.setState({ currentUser: currentUser, userReady: true });

    DashboardService.getDashboardData().then((res) => {
      //console.log(res.data);
      let dashboard = res.data;
      this.setState({
        totalOrders: dashboard.totalOrders,
        openOrders: dashboard.openOrders,
        totalStores: dashboard.totalStores,
        totalVendors: dashboard.totalVendors,
        acceptedOrders: dashboard.acceptedOrders,
        deliveredOrders: dashboard.deliveredOrders,
        totalRiders: dashboard.totalRiders,
        todaysOrders: dashboard.todaysOrders,
        todaysAvailableRiders: dashboard.todaysAvailableRiders,
      });
    });

    AttendanceService.getRiderLogoutData().then((res) => {
      console.log(res.data);
      this.setState({ logoutRiders: res.data });
    });
    // console.log(this.state.attendance);
    RiderService.getOnlineRiders().then((res) => {
      console.log(res.data);
      this.setState({ onlineRiders: res.data });
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
              </div>

              {/* <!-- Content Row --> */}
              <div className="row">
                {/* <!-- Pending Requests Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                            <a href="/ordersByStatus/OPEN">Pending Orders</a>
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">
                            {this.state.openOrders}
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-list fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-primary shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                            <a href="/ordersByStatus/ACCEPTED">
                              Accepted Orders
                            </a>
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">
                            {this.state.acceptedOrders}
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-calendar-plus fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-success shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                            <a href="/ordersByStatus/DELIVERED">
                              Delivered Orders
                            </a>
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">
                            {this.state.deliveredOrders}
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-calendar-check fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-info shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                            <a href="/orders">Total Orders</a>
                          </div>
                          <div className="row no-gutters align-items-center">
                            <div className="col-auto">
                              <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                {this.state.totalOrders}
                              </div>
                            </div>
                            <div className="col">
                              <div className="progress progress-sm mr-2">
                                {/* <div className="progress-bar bg-info" role="progressbar"
                                                        style="width: 50%" aria-valuenow="50" aria-valuemin="0"
                                                        aria-valuemax="100"></div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
              <div className="row">
                {/* <!-- Pending Requests Card Example --> */}

                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-primary shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                            <a href="/ordersByStatus/TODAY"> Today's Orders</a>
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">
                            {this.state.todaysOrders}
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-list-ol fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                            <a href="/riders-today/TODAY">Today's Available</a>{" "}
                            / <a href="/riders">All Riders</a>
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">
                            {this.state.todaysAvailableRiders} /{" "}
                            {this.state.totalRiders}
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-user-plus fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                            <a href="/riders"> Total Riders</a>
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">
                            {this.state.totalRiders}
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-users fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-success shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                            <a href="/stores">Total Stores</a>
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">
                            {this.state.totalStores}
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-hospital fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-info shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                            <a href="/vendors">Total Vendors</a>
                          </div>
                          <div className="row no-gutters align-items-center">
                            <div className="col-auto">
                              <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                {this.state.totalVendors}
                              </div>
                            </div>
                            <div className="col">
                              <div className="progress progress-sm mr-2"></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-building fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" >
                <div className="card col-md-6" >
                  <h4 className="text-center">Recent Logout Time (Alert)</h4>
                  <div className="card-body" id="scroll" >
                    <div className="row">
                      
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>Rider Name</th>
                            <th> Mobile</th>
                            <th>Logout Time</th>
                            <th>Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.logoutRiders.map((logoutRider) => (
                            <tr>
                              <td>{logoutRider.fullname}</td>
                              <td>{logoutRider.mobile}</td>
                              <td>{logoutRider.activityDate}</td>
                              <td>{logoutRider?.duration}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      
                    </div>
                  </div>
                </div>

                {/* <div className="card col-md-1" style={{"height" : "190px"}}>
                  
                </div> */}




                <div className="card col-md-6" >
                  <h4 className="text-center">Online/Offline Time (Alert)</h4>
                  <div className="card-body" id="scroll">
                    <div className="row">
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>Rider Name</th>
                            <th> Mobile</th>
                            <th>Last Online</th>
                            <th>Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.onlineRiders.map((onlineRider) => (
                            <tr>
                              <td>{onlineRider.fullname}</td>
                              <td>{onlineRider.mobile}</td>
                              <td>{onlineRider.activityDate}</td>
                              <td>{onlineRider?.duration}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default DashboardComponent;

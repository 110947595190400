import React, { Component } from 'react'
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import DashboardService from '../services/DashboardService'
import SideBarStoreComponent from './SideBarStoreComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';


class DashboardStoreComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            redirect: null,
            userReady: false,
            currentUser: null,
            storeOpenOrders: 0,
            storeAcceptedOrders: 0,
            storeDeliveredOrders: 0,
            storeTotalOrders: 0,
        };

    }


    componentDidMount() {
        const currentUser = AuthService.getCurrentUser();
        if (!currentUser) {
            this.setState({ redirect: "/login" });
        }
        this.setState({ currentUser: currentUser, userReady: true })

        DashboardService.getStoreDashboardData(currentUser.storeId).then((res) => {

            console.log(res.data);
            let dashboard = res.data;
            this.setState({
                storeOpenOrders: dashboard.storeOpenOrders,
                storeAcceptedOrders: dashboard.storeAcceptedOrders,
                storeDeliveredOrders: dashboard.storeDeliveredOrders,
                storeTotalOrders: dashboard.storeTotalOrders,

            });
        });

    }



    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        // const { currentUser } = this.state;

        return (
            // <div className="container-fluid">
            //   {(this.state.userReady) ?
            //   <div>
            //   <header className="jumbotron">
            //     <h3>
            //       <strong>{currentUser.username}</strong> Dashboard Profile
            //     </h3>
            //   </header>
            //   <p>
            //     <strong>Token:</strong>{" "}
            //     {currentUser.accessToken.substring(0, 20)} ...{" "}
            //     {currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
            //   </p>
            //   <p>
            //     <strong>Id:</strong>{" "}
            //     {currentUser.id}
            //   </p>
            //   <p>
            //     <strong>Email:</strong>{" "}
            //     {currentUser.email}
            //   </p>
            //   <strong>Authorities:</strong>
            //   <ul>
            //     {currentUser.roles &&
            //       currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
            //   </ul>
            // </div>: null}
            // </div>









            // <!-- Page Wrapper -->
            <div id="wrapper">
                <SideBarStoreComponent />

                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">

                    {/* <!-- Main Content --> */}
                    <div id="content">

                        <TopBarComponent />


                        {/* <!-- Begin Page Content --> */}
                        <div className="container-fluid">

                            {/* <!-- Page Heading --> */}
                            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                <h1 className="h3 mb-0 text-gray-800">Dashboard - Store </h1>

                            </div>

                            {/* <!-- Content Row --> */}
                            <div className="row">

                                {/* <!-- Pending Requests Card Example --> */}
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-warning shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                                    <a href="/ordersByStatus/OPEN"> Pending Orders</a></div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{this.state.storeOpenOrders}</div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-comments fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Earnings (Monthly) Card Example --> */}
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-primary shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                    <a href="/ordersByStatus/ACCEPTED">Accepted Orders</a></div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{this.state.storeAcceptedOrders}</div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Earnings (Monthly) Card Example --> */}
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-success shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                    <a href="/ordersByStatus/DELIVERED"> Delivered Orders</a></div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{this.state.storeDeliveredOrders}</div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Earnings (Monthly) Card Example --> */}
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-info shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1"> <a href="/orders">Total Orders</a>
                                                    </div>
                                                    <div className="row no-gutters align-items-center">
                                                        <div className="col-auto">
                                                            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{this.state.storeTotalOrders}</div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="progress progress-sm mr-2">
                                                                {/* <div className="progress-bar bg-info" role="progressbar"
                        style="width: 50%" aria-valuenow="50" aria-valuemin="0"
                        aria-valuemax="100"></div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* <!-- Content Row --> */}



                        </div>
                        {/* <!-- /.container-fluid --> */}

                    </div>
                    {/* <!-- End of Main Content --> */}
                    <BackEndFooterComponent />

                </div>
                {/* <!-- End of Content Wrapper --> */}

            </div>
            // <!-- End of Page Wrapper --> 










        );
    }
}

export default DashboardStoreComponent
